import React from 'react'
import schoolAbtUs from '../Images/schoolAbtUs.jpeg'

export default function AboutUs() {
  return (
    <>
<div className='container carouselImg' style={{ maxWidth: '1400px' }}>
<div id="carouselExampleCaptions" className="carousel slide">
  <div className="carousel-inner">
    <div className="carousel-item active image-abtUscontainer ">
        <div className='overlay'>
        <div className="centered-text">
        <h2>About Us</h2>
        </div>
        </div>
      {/* <img src={imgGalCarosel} className="d-block responsive" alt="..." width={'600px'} height={'400'}/> */}
    </div>
  </div>
</div>
</div>
<div className='container my-3 py-1 bg-light text-dark shadow-lg p-3 mb-5 bg-white rounded' style={{ maxWidth: '1200px' }}>
   <div className=" mb-1 mt-3 bg-light text-dark">
   <div className="row g-0">
    <div className="col-md-8">
      <div className="card-body">
      <h2 style={{ color: '#2D50A4', fontFamily: 'Poppins'}}>About Our School.</h2>
        <p className="">
        Cambridge School is a dedicated educational institution committed to providing quality education and fostering holistic development. Our mission is to nurture young minds, empower them with knowledge, and prepare them for a bright future.
        </p>
        <p>
        Founded with a vision to create responsible and confident individuals, Cambridge School offers a nurturing environment where students can thrive academically, socially, and personally. Our experienced and dedicated faculty members work tirelessly to inspire curiosity, critical thinking, and a love for learning.
        </p>
        <p>
        The school has its own building in a lush green area and a big play ground. The main building comprises three floors and a indoor multi- purpose area. The School includes Science labs, Library, well equipped infirmary, Projector room etc. Every class room has a smart board with required software to promote learning potential of the students.
        </p>
        <p>
        At Cambridge School, we believe in not only academic excellence but also in cultivating strong values, leadership qualities, and a sense of community. We encourage our students to explore their talents and interests through a wide range of extracurricular activities, ensuring a well-rounded education.
        </p>
        <p>
        Join us on this educational journey, where every child's potential is recognized and nurtured, and together, we create a brighter future for our students.
        </p>
        <p className="card-text"><small className="text-body-secondary">CAMBRIDGE SCHOOL</small></p>
      </div>
    </div>
    <div className="col-md-4 d-flex align-items-center">
    <div className="image-containers shadow  my-5 bg-white rounded">
      <img src={schoolAbtUs} className="img-fluid rounded-start" alt="..." />
    </div>
    </div>
    </div>
    </div>
 </div>
    </>
  )
}
